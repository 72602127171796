import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  fetchUtils,
  CardActions,
  ShowButton,
  DateInput,
  ReferenceInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import ServiceCategoryForm from '../../forms/ServiceCategoryForm';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="Conferma" />
  </Toolbar>
));

export const ServiceCategoryCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
        <ServiceCategoryForm />
      </SimpleForm>
    </Create>
  );

  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput reference="suppliers" source="first_name"><SelectInput optionText="id" /></ReferenceInput>
        <ReferenceInput reference="users"><SelectInput optionText="id" /></ReferenceInput>
        <ReferenceInput reference="users"><SelectInput optionText="id" /></ReferenceInput>

        <TextInput label="Nome" allowEmpty={false} source="first_name" />


        <NumberInput source="done_by.id" />
        <NumberInput source="supplier.id" />
        <DateInput source="address.line1" />
        <NumberInput source="service.id" />
        <DateInput source="date" />
        <ArrayInput source="timeslots">
          <SimpleFormIterator>
            <TextInput source="start" />
            <TextInput source="end" />
          </SimpleFormIterator>

        </ArrayInput>
        <NumberInput source="price_at_request_time" />
        <TextInput source="notes" />
        <TextInput source="status.value" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Create>
  );
};

export default ServiceCategoryCreate;
