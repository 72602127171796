import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Responsive,
  SimpleList,
  ReferenceField,
} from 'react-admin';


import StatusField from '../../components/status-field';
import PriceField from '../../components/price-field';

// import UserFilters from 'components/filters/Service';

const ReservationList = (props) => (
  <List title="Gestione servizi" {...props}>
    <Responsive
      small={(
        <SimpleList
          primaryText={(service) => (service ? service.name : '')}
          tertiaryText={(service) => (service ? service.id : '')}
        />
      )}
      medium={(
        <Datagrid rowClick="show">
          <TextField source="id" label="ID" />
          <StatusField source="status" label="Status" />
          <ReferenceField source="reservation_request.id" label="Richiesta prenotazione" reference="reservation-requests">
            <TextField source="id" />
          </ReferenceField>
          <DateField source="reservation_request.date" label="Data" />
          <PriceField label="Totale" />
          <BooleanField source="is_payed" label="Pagamento effettuato" />
          <DateField showTime source="created_at" label="Data creazione" />
          <DateField source="updated_at" label="Data ultimo aggiornamento" showTime />
        </Datagrid>
      )}
    />
  </List>
);


export default ReservationList;
