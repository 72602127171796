import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  fetchUtils,
  CardActions,
  ShowButton,
  DateInput,
  ReferenceInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import ServiceForm from '../../forms/ServiceForm';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="Conferma" />
  </Toolbar>
));

export const ServiceCategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
      <ServiceForm />
    </SimpleForm>
  </Create>
);

export default ServiceCategoryCreate;
