import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  useDataProvider,
  DateInput,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import moment from 'moment';

import UserAddressInput from '../components/users-address-input';
import UserPaymentInput from '../components/users-payment-input';
import TimeslotsInput from '../components/timeslots-input';

const ReservationRequestForm = () => {
  const formState = useFormState();
  const dataProvider = useDataProvider();
  const [addresses, setAddresses] = useState(undefined);

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <ReferenceInput label="Needy" source="needy_id" reference="needy">
          <AutocompleteInput optionText={(choice) => (choice ? `${choice.id} - ${choice.full_name} - ${moment(choice.birthday).format('DD/MM/YYYY')}` : '')} />
        </ReferenceInput>
      </Grid>

      <Grid item xs={12} md={6}>
        <ReferenceInput label="Servizio" source="service_id" reference="services">
          <AutocompleteInput optionText={(choice) => (choice ? `${choice.id} - ${choice.name}` : '')} />
        </ReferenceInput>
      </Grid>

      <Grid item xs={12} md={6}>
        <ReferenceInput label="Fornitore" source="supplier_id" reference="suppliers">
          <AutocompleteInput optionText={(choice) => (choice ? `${choice.id} - ${choice.info.rag_soc}` : '')} />
        </ReferenceInput>
      </Grid>


      <Grid item xs={12} md={12}>
        <UserAddressInput userId={formState.values.needy_id} />
      </Grid>

      <Grid item xs={12} md={12}>
        <UserPaymentInput userId={formState.values.needy_id} />
      </Grid>

      <DateInput source="date" label="Data" />

      <Grid item xs={12} md={12}>
        <TimeslotsInput
          date={formState.values.date}
          serviceId={formState.values.service_id}
          supplierId={formState.values.supplier_id}
        />
      </Grid>

    </Grid>
  );
};

ReservationRequestForm.defaultProps = {
  addLabel: false,
};

export default ReservationRequestForm;
