import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';


const SupplierServiceForm = () => {
  const form = useForm();
  const { values } = form.getState();

  return (
    <Grid container>
      {values.id ? <TextInput source="id" disabled label="ID" /> : null}
      <ReferenceInput source="service_id" reference="services">
        <AutocompleteInput optionText={(choice) => (choice ? `${choice.id} - ${choice.name}` : '')} />
      </ReferenceInput>
      <RichTextInput source="description" label="Descrizione" />
      <ReferenceInput source="supplier_id" reference="suppliers">
        <AutocompleteInput optionText={(choice) => (choice ? `${choice.id} - ${choice.info.rag_soc}` : '')} />
      </ReferenceInput>
      <NumberInput source="price" label="Prezzo" />
      <NumberInput source="duration" label="Durata" />
    </Grid>
  );
};

export default SupplierServiceForm;
