import React from 'react';
import { parseTotalPrice } from '../../utils';

const PriceField = ({ record, source }) => {
  console.log(record);

  const formattedPrice = (() => {
    if (record.price) {
      return `${(record.price / 100).toFixed(2)}€`;
    }

    if (record.total_price) {
      return `${record.total_price.toFixed(2)} €`;
    }

    if (record.hourly_price) {
      return `${record.hourly_price} €`;
    }

    if (record.reservation_request) { /* is a reservation */
      const { price_at_request_time: price, timeslots, service } = record.reservation_request;
      return parseTotalPrice(price, service.duration, timeslots.length);
    } /* is a reservation request */
    const { price_at_request_time: price, timeslots, service } = record;
    return parseTotalPrice(price, service.duration, timeslots.length);
  })();


  return (
    <span>
      {formattedPrice}
    </span>
  );
};

PriceField.defaultProps = {
  addLabel: true,
};

export default PriceField;
