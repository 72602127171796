import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  required,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  useDataProvider,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import TextField from '@material-ui/core/TextField';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import Modal from '@material-ui/core/Modal';
import Swal from 'sweetalert2';

import Paper, { PaperProps } from '@material-ui/core/Paper';
import DialogContentText from '@material-ui/core/DialogContentText';
import AddressInput from '../address-input';


const UserAddressQuickCreateButton = ({ userId, onAddComplete = () => {} }) => {
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [addressInput, setAddressInput] = useState('');
  const [address, setAddress] = useState('');

  const toggleDialog = (val = !showDialog) => {
    setShowDialog(val);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    dataProvider.create(`users/${userId}/addresses`, { data: address })
      .then((resp) => {
        onAddComplete();
        setIsSubmitting(false);
        toggleDialog(false);
      });
  };


  return (
    <>
      <Button onClick={toggleDialog} label="Nuovo" disabled={userId === undefined}>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={() => toggleDialog(false)}
        PaperComponent={Paper}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Crea nuovo indirizzo
        </DialogTitle>
        <DialogContent>
          <PlacesAutocomplete
            style={{ width: '100%' }}
            value={addressInput}
            onChange={setAddressInput}
              // eslint-disable-next-line no-shadow
            onSelect={async (address) => {
              setAddressInput(address);
              const data = await geocodeByAddress(address);

              try {
                const parsedAddress = {
                  address: `${data[0].address_components.find((item) => item.types.includes('route')).long_name}, ${data[0].address_components.find((item) => item.types.includes('street_number')).long_name}`,
                  city: data[0].address_components.find((item) => item.types.includes('locality')).long_name,
                  postalCode: data[0].address_components.find((item) => item.types.includes('postal_code')).long_name,
                  coordinates: {
                    lat: data[0].geometry.location.lat(),
                    lng: data[0].geometry.location.lng(),
                  },
                };

                setAddress(parsedAddress);
              } catch (err) {
                Swal.fire('Errore', 'Indirizzo non valido', 'error');
              }
            }}
          >
            {({
              getInputProps, suggestions, getSuggestionItemProps, loading,
            }) => (
              <div className="address-selection-wrapper">
                <h3>Ricerca indirizzo</h3>
                <TextField
                  style={{ width: '100%' }}
                  label="Indirizzo"
                  variant="filled"
                  {...getInputProps({
                    placeholder: 'Cerca indirizzo ...',
                    className: 'location-search-input',
                  })}
                />

                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                      // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button onClick={() => toggleDialog(false)} label="Annulla">
            <IconCancel />
          </Button>
          <Button onClick={handleSubmit} label="Salva" disabled={address === ''}>
            <IconContentAdd />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserAddressQuickCreateButton;
