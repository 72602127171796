import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Responsive,
  SimpleList,
} from 'react-admin';

const ServiceCategoryList = (props) => (
  <List title="Gestione categorie servizi" {...props}>
    <Responsive
      small={(
        <SimpleList
          primaryText={(serviceCategory) => (serviceCategory ? serviceCategory.name : '')}
          tertiaryText={(serviceCategory) => (serviceCategory ? serviceCategory.id : '')}
        />
      )}
      medium={(
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="name" />
          <DateField source="created_at" label="Data creazione" showTime />
          <DateField source="updated_at" label="Data ultimo aggiornamento" showTime />
        </Datagrid>
              )}
    />
  </List>
);

export default ServiceCategoryList;
