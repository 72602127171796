import React, { useEffect, useCallback } from 'react';
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useEditController,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

import SupplierServiceForm from '../../forms/SupplierServiceForm';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="Conferma" />
  </Toolbar>
));


const SupplierServiceEdit = (props) => {
  const {
    record,
  } = useEditController(props);

  return (
    <Edit title="Modifica servizio fornitore" {...props}>
      {record === undefined ? <CircularProgress /> : (
        <SimpleForm toolbar={<CustomToolbar />}>
          <SupplierServiceForm />
        </SimpleForm>
      )}
    </Edit>
  );
};

export default SupplierServiceEdit;
