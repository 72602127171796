import React, { useCallback } from 'react';
import {
  Create,
  SimpleForm,
  Toolbar,
  SaveButton,
  useDataProvider,
  useRedirect,
  useCreate,
  useEditController,
  Edit,
} from 'react-admin';
import { useForm } from 'react-final-form';

import { withStyles } from '@material-ui/core';

import ReservationRequestEditForm from '../../forms/ReservationRequestEditForm';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="Conferma" />
  </Toolbar>
));

export const ReservationRequestEdit = (props) => {
  const {
    record,
  } = useEditController(props);

  console.log('record', record);


  return (
    <Edit title="Modifica richiesta prenotazione" {...props}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
        <ReservationRequestEditForm />
      </SimpleForm>
    </Edit>

  );
};

export default ReservationRequestEdit;
