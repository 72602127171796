import React, { useEffect } from 'react';
import {
  Show,
  TextField,
  DateField,
  BooleanField,
  ReferenceField,
  useShowController,
  TabbedShowLayout,
  Tab,
  ArrayField,
  Datagrid,
  TopToolbar,
  EditButton,
  DeleteButton,
  useCreate,
  useRefresh,
} from 'react-admin';
import { CircularProgress, Button } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

import StatusField from '../../components/status-field';
import SignatureField from '../../components/signature-field';
import AddressField from '../../components/address-field';
import PriceField from '../../components/price-field';

const Actions = ({
  basePath, data, resource, ...rest
}) => {
  const [startReservation, startReservationData] = useCreate(`reservations/${data.id}/status`, {
    status: 'in-progress',
    message: 'Prestazione iniziata da amministratore',
    reservationId: data.id,
  });

  const [stopReservation, stopReservationData] = useCreate(`reservations/${data.id}/status`, {
    status: 'completed',
    message: 'Prestazione conclusa da amministratore',
    reservationId: data.id,
  });

  const refresh = useRefresh();

  useEffect(() => {
    if (startReservationData.loaded) {
      refresh();
    }
  }, [startReservationData]);

  useEffect(() => {
    if (stopReservationData.loaded) {
      refresh();
    }
  }, [stopReservationData]);

  return (
    <TopToolbar>
      {/* Add your custom actions */}
      {data.status.value === 'booked' || data.status.value === 'in-progress' ? (
        <>

          {data.status.value === 'booked' ? (
            <Button
              color="primary"
              size="small"
              startIcon={<PlayArrowIcon />}
              onClick={() => {
                startReservation();
              }}
            >
              Inizia prestazione
            </Button>
          ) : null}

          {data.status.value === 'in-progress' ? (
            <Button
              color="primary"
              size="small"
              startIcon={<StopIcon />}
              onClick={() => {
                stopReservation();
              }}
            >
              Concludi prestazione
            </Button>
          ) : null}

        </>
      ) : null}
      <EditButton basePath={basePath} record={data} label="Modifica" />
      <DeleteButton basePath={basePath} record={data} label="Cancella" />
    </TopToolbar>
  );
};


const ReservationShow = (props) => {
  const { record } = useShowController(props);

  if (record === undefined) {
    return <CircularProgress />;
  }

  return (
    <Show {...props} actions={<Actions />}>
      <TabbedShowLayout>
        <Tab label="Generale">
          <TextField source="id" label="ID" />
          <StatusField source="status" label="Stato" />
          <ReferenceField source="reservation_request.id" label="Richiesta di prenotazione" reference="reservation-requests">
            <TextField source="id" />
          </ReferenceField>
          <SignatureField source="proof_of_conclusion_uri" label="Firma di conclusione" />
          <BooleanField source="is_payed" label="Pagamento effettuato" />
          {record.review_id ? (
            <ReferenceField source="review_id" label="Recensione" reference="reservation-reviews">
              <TextField source="id" />
            </ReferenceField>
          ) : null}
          <DateField source="created_at" label="Data creazione" showTime />
        </Tab>
        <Tab label="Richiesta di prenotazione">
          <TextField source="reservation_request.id" label="ID" />

          <StatusField source="reservation_request.status" label="Stato" />

          <ReferenceField source="reservation_request.done_by.id" reference="users" label="Fatta da">
            <TextField source="full_name" />
          </ReferenceField>

          <ReferenceField source="reservation_request.needy.id" reference="users" label="Needy">
            <TextField source="full_name" />
          </ReferenceField>

          <ReferenceField source="reservation_request.supplier.id" reference="users" label="Fornitore">
            <TextField source="info.rag_soc" />
          </ReferenceField>

          <AddressField source="reservation_request.address" label="Indirizzo" />

          <ReferenceField source="reservation_request.service.service_id" reference="services" label="Servizio">
            <TextField source="name" />
          </ReferenceField>

          <TextField source="reservation_request.notes" label="Note" />

          <DateField source="reservation_request.date" label="Data" />

          <ArrayField source="reservation_request.timeslots" label="Slot">
            <Datagrid>
              <TextField source="start" label="Inizio" />
              <TextField source="end" label="Fine" />
            </Datagrid>
          </ArrayField>

          <PriceField label="Costo totale" />

          <DateField source="created_at" label="Data creazione" showTime />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ReservationShow;
