import React, { useState } from 'react';
import {
  Button,
  useDataProvider,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';


const UserAddressQuickCreateButton = ({ userId, onAddComplete = () => {} }) => {
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [cvc, setCvc] = useState();
  const [fullName, setFullName] = useState();

  const toggleDialog = (val = !showDialog) => {
    setShowDialog(val);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    dataProvider.create(`users/${userId}/payment-methods`, {
      data: {
        type: 'card',
        card: {
          number: cardNumber, exp_month: month, exp_year: year, cvc,
        },
        billing_details: { name: fullName },
      },
    })
      .then((resp) => {
        onAddComplete();
        setIsSubmitting(false);
        toggleDialog(false);
      });
  };


  return (
    <>
      <Button onClick={toggleDialog} label="Nuovo" disabled={userId === undefined}>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={() => toggleDialog(false)}
        PaperComponent={Paper}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Crea nuovo metodo di pagamento
        </DialogTitle>
        <DialogContent>
          <div className="payment-method-input-dialog">
            <Grid container>
              <Grid item xs={12}>
                <TextField id="filled-basic" label="Numero carta" variant="filled" onChange={(event) => setCardNumber(event.target.value)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="filled-basic" label="Mese scadenza (Es. 01)" variant="filled" onChange={(event) => setMonth(event.target.value)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="filled-basic" label="Anno scadenza (Es. 20)" variant="filled" onChange={(event) => setYear(event.target.value)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="filled-basic" label="CVC" variant="filled" onChange={(event) => setCvc(event.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField id="filled-basic" label="Intestatario" variant="filled" onChange={(event) => setFullName(event.target.value)} />
              </Grid>
            </Grid>

          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: 30 }}>
          <Button onClick={() => toggleDialog(false)} label="Annulla">
            <IconCancel />
          </Button>
          <Button onClick={handleSubmit} label="Salva" disabled={!(cardNumber !== undefined && month !== undefined && year !== undefined && cvc !== undefined && fullName !== undefined)}>
            <IconContentAdd />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserAddressQuickCreateButton;
