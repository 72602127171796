import React, { useState } from 'react';
import { TextInput } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import Modal from '@material-ui/core/Modal';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import { getFullAddress } from '../../utils';

const AddressInput = ({ label, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState('');
  const [addressInput, setAddressInput] = useState('');
  const [address, setAddress] = useState('');

  const handleSubmit = () => {
    onChange(address);
    setShowModal(false);
    document.activeElement.blur();
    setAddress('');
    setValue(getFullAddress(address));
    setAddressInput('');
  };


  return (
    <>
      <TextInput
        label={label}
        allowEmpty={false}
        source="address"
        value={value}
        InputProps={{
          readOnly: true,
        }}
        onFocus={() => setShowModal(true)}
      />

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-content">

          <PlacesAutocomplete
            style={{ width: '100%' }}
            value={addressInput}
            onChange={setAddressInput}
              // eslint-disable-next-line no-shadow
            onSelect={async (address) => {
              setAddressInput(address);
              const data = await geocodeByAddress(address);

              try {
                const parsedAddress = {
                  address: `${data[0].address_components.find((item) => item.types.includes('route')).long_name}, ${data[0].address_components.find((item) => item.types.includes('street_number')).long_name}`,
                  city: data[0].address_components.find((item) => item.types.includes('locality')).long_name,
                  postalCode: data[0].address_components.find((item) => item.types.includes('postal_code')).long_name,
                  coordinates: {
                    lat: data[0].geometry.location.lat(),
                    lng: data[0].geometry.location.lng(),
                  },
                };

                setAddress(parsedAddress);
              } catch (err) {
                Swal.fire('Errore', 'Indirizzo non valido', 'error');
              }
            }}
          >
            {({
              getInputProps, suggestions, getSuggestionItemProps, loading,
            }) => (
              <div className="address-selection-wrapper">
                <h3>Ricerca indirizzo</h3>
                <TextField
                  label="Indirizzo"
                  variant="filled"
                  {...getInputProps({
                    placeholder: 'Cerca indirizzo ...',
                    className: 'location-search-input',
                  })}
                />

                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                      // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <div className="modal-buttons-wrapper">
            <Button variant="contained" color="primary" disabled={address === ''} onClick={handleSubmit}>Conferma</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddressInput;
