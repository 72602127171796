import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  fetchUtils,
} from 'react-admin';
import { stringify, parse } from 'query-string';
import { DELETE_MANY } from 'ra-core';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      const query = {
        page,
        perPage,
        q: params.filter.q,
        // sort: JSON.stringify([field, order]),
        // filter: JSON.stringify(params.filter),
      };

      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: {
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }),
        },
      };
    }
    case GET_ONE:
      console.log('params', params);

      if (params === undefined) {
        return {
          url: `${API_URL}/${resource}`,
          options: {
            headers: new Headers({
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            }),
          },
        };
      }

      console.log(`${API_URL}/${resource}/${params.id}`);


      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: {
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }),
        },
      };
    case GET_MANY: {
      const query = `noPaginate&filter[id]=${params.ids.join(',')}`;

      return {
        url: `${API_URL}/${resource}?${query}`,
        options: {
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }),
        },
      };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = `filter[id]=${params.ids.join(',')}`;

      console.log('GET MANY');
      /*
        const query = {
            page,
            perPage,
            //sort: JSON.stringify([field, order]),
            //range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
            //filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
        };

        */
      return {
        url: `${API_URL}/${resource}?${query}`,
        options: {
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }),
        },
      };
    }
    case UPDATE:

      if (resource === 'users' || resource === 'service-categories') {
        const { data } = params;
        const { media } = data;
        const newPictures = [];

        Object.keys(media).map((key) => {
          if (media[key].rawFile !== undefined && media[key].rawFile instanceof File) {
            newPictures.push({ ...media[key], title: key });
          }
        });

        // if (profile.rawFile !== undefined && profile.rawFile instanceof File) {
        //   newPictures.push({ ...profile, title: 'profile' });
        // }

        // if (cover.rawFile !== undefined && cover.rawFile instanceof File) {
        //   newPictures.push({ ...cover, title: 'cover' });
        // }

        return Promise.all(newPictures.map(convertFileToBase64))
          .then((base64Pictures) => base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${newPictures[index].title}`,
          })))
          .then((pictures) => ({
            url: `${API_URL}/${resource}/${params.id}`,
            options: {
              method: 'PUT',
              body: JSON.stringify({
                ...params.data,
                pictures,
              }),
              headers: new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              }),
            },
          }));
      }

      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: {
          method: 'PUT',
          body: JSON.stringify(params.data),
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }),
        },
      };
    case CREATE:
      const { data } = params;
      const { media } = data;
      if (media) {
        const newPictures = [];

        Object.keys(media).map((key) => {
          console.log('iamge!', key);

          if (media[key].rawFile !== undefined && media[key].rawFile instanceof File) {
            newPictures.push({ ...media[key], title: key });
          }
        });

        return Promise.all(newPictures.map(convertFileToBase64))
          .then((base64Pictures) => base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${newPictures[index].title}`,
          })))
          .then((pictures) => ({
            url: `${API_URL}/${resource}`,
            options: {
              method: 'POST',
              body: JSON.stringify({
                ...params.data,
                pictures,
              }),
              headers: new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              }),
            },
          }));
      }
      return {
        url: `${API_URL}/${resource}`,
        options: {
          method: 'POST',
          body: JSON.stringify(params.data),
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }),
        },
      };
    case DELETE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }),
        },
      };
    case DELETE_MANY: {
      return {
        url: `${API_URL}/${resource}`,
        options: {
          body: JSON.stringify(params),
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }),
        },
      };
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const { headers, json } = response;

  console.log(response, params, type, resource);


  switch (type) {
    case GET_LIST:
      return {
        data: json.data.map((x) => x),
        total: json.meta.total,
      };
    case GET_ONE:
      return {
        data: json.data ? json.data : json,
      };
    case CREATE:
      return { data: { ...params.data, id: json.id } };
    default:
      return { data: json.data };
  }
};

const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;

  reader.readAsDataURL(file.rawFile);
});

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;

  const httpData = convertDataProviderRequestToHTTP(type, resource, params);

  if (httpData.then !== undefined) {
    return httpData
      .then(({ url, options }) => fetchJson(url, options))
      .then((response) => convertHTTPResponseToDataProvider(response, type, resource, params));
  }

  const { url, options } = httpData;
  return fetchJson(url, options)
    .then((response) => convertHTTPResponseToDataProvider(response, type, resource, params));
};
