import React from 'react';
import {
  Admin, Resource, ListGuesser, ShowGuesser, EditGuesser,
} from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';

import UserIcon from '@material-ui/icons/Group';
import ServiceIcon from '@material-ui/icons/ViewAgenda';
import ServiceCategoryIcon from '@material-ui/icons/ViewWeek';
import ReservationIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReservationRequestIcon from '@material-ui/icons/Assignment';
import ReservationReviewIcon from '@material-ui/icons/RateReview';
import SupplierIcon from '@material-ui/icons/Work';
import CarerIcon from '@material-ui/icons/Person';
import NeedyIcon from '@material-ui/icons/PermIdentity';

import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

import {
  UserList,
  ServiceCategoryList,
  ServiceList,
  ReservationRequestList,
  ReservationList,
  SupplierList,
  ReservationReviewList,
  AnonymousReservationRequestList,
  SupplierServiceList,
} from './CRUDs/lists';
import {
  UserCreate,
  ReservationRequestCreate,
  ServiceCategoryCreate,
  SupplierServiceCreate,
  ServiceCreate,
} from './CRUDs/creates';
import {
  UserEdit,
  SupplierEdit,
  ServiceCategoryEdit,
  ServiceEdit,
  SupplierServiceEdit,
  ReservationRequestEdit,
} from './CRUDs/edits';
import Dashboard from './dashboard';
import {
  UserShow,
  ReservationShow,
  ReservationRequestShow,
  ServiceCategoryShow,
  ServiceShow,
  AnonymousReservationRequestShow,
  SupplierServiceShow,

} from './CRUDs/shows';

const theme = createMuiTheme({
  palette: {
    primary: amber,
    secondary: amber,
  },
});

const App = () => (
  <Admin
    theme={theme}
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={Dashboard}
  >

    <Resource
      options={{ label: 'Utenti' }}
      icon={UserIcon}
      name="users"
      list={UserList}
      show={UserShow}
      edit={UserEdit}
      create={UserCreate}
    />

    <Resource
      options={{ label: 'Fornitori' }}
      icon={SupplierIcon}
      name="suppliers"
      list={SupplierList}
      show={ShowGuesser}
      edit={SupplierEdit}
    />

    <Resource
      options={{ label: 'Carer' }}
      icon={CarerIcon}
      name="carers"
      list={UserList}
      show={UserShow}
      edit={UserEdit}
    />

    <Resource
      options={{ label: 'Needy' }}
      icon={NeedyIcon}
      name="needy"
      list={UserList}
      show={UserShow}
      edit={UserEdit}
    />

    <Resource
      options={{ label: 'Categorie Servizi' }}
      icon={ServiceIcon}
      name="service-categories"
      list={ServiceCategoryList}
      show={ServiceCategoryShow}
      edit={ServiceCategoryEdit}
      create={ServiceCategoryCreate}
    />

    <Resource
      options={{ label: 'Servizi' }}
      icon={ServiceCategoryIcon}
      name="services"
      list={ServiceList}
      show={ServiceShow}
      edit={ServiceEdit}
      create={ServiceCreate}
    />

    <Resource
      options={{ label: 'Servizi dei fornitori' }}
      icon={ServiceCategoryIcon}
      name="supplier-services"
      list={SupplierServiceList}
      show={SupplierServiceShow}
      edit={SupplierServiceEdit}
      create={SupplierServiceCreate}
    />

    <Resource
      options={{ label: 'Richieste customer services' }}
      icon={ReservationRequestIcon}
      name="anonymous-reservation-requests"
      list={AnonymousReservationRequestList}
      show={AnonymousReservationRequestShow}
      edit={EditGuesser}
      create={undefined}
    />

    <Resource
      options={{ label: 'Richieste prenotazioni' }}
      icon={ReservationRequestIcon}
      name="reservation-requests"
      list={ReservationRequestList}
      show={ReservationRequestShow}
      edit={ReservationRequestEdit}
      create={ReservationRequestCreate}
    />

    <Resource
      options={{ label: 'Prenotazioni' }}
      icon={ReservationIcon}
      name="reservations"
      list={ReservationList}
      show={ReservationShow}
      edit={EditGuesser}
      create={undefined}
    />

    <Resource
      options={{ label: 'Recensioni prenotazioni' }}
      icon={ReservationReviewIcon}
      name="reservation-reviews"
      list={ReservationReviewList}
      show={ShowGuesser}
      edit={EditGuesser}
      create={undefined}
    />

  </Admin>
);

export default App;
