import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Responsive,
  SimpleList,
  ReferenceField,
  NumberField,
} from 'react-admin';

const ReservationReviewList = (props) => (
  <List title="Recensioni" {...props}>
    <Responsive
      small={(
        <SimpleList
          primaryText={(service) => (service ? service.name : '')}
          tertiaryText={(service) => (service ? service.id : '')}
        />
      )}
      medium={(
        <Datagrid rowClick="show">
          <TextField source="id" label="ID" />
          <ReferenceField source="reservation_id" label="Prenotazione" reference="reservations">
            <TextField source="id" />
          </ReferenceField>
          <NumberField source="score" />
          <ReferenceField source="user_id" label="Eseguita da" reference="users">
            <TextField source="full_name" />
          </ReferenceField>
          <ReferenceField source="supplier_id" label="Fornitore" reference="users">
            <TextField source="info.rag_soc" />
          </ReferenceField>
          <TextField source="notes" label="Testo" />
          <DateField source="created_at" label="Data creazione" showTime />
          <DateField source="updated_at" label="Data ultimo aggiornamento" showTime />
        </Datagrid>
      )}
    />
  </List>
);

export default ReservationReviewList;
