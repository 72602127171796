import React, { useCallback } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  fetchUtils,
  CardActions,
  ShowButton,
  DateInput,
  ReferenceInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  useDataProvider,
  useRedirect,
  useCreate,
} from 'react-admin';
import { useForm, useField } from 'react-final-form';

import { withStyles } from '@material-ui/core';

import ReservationRequestForm from '../../forms/ReservationRequestForm';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomSave = ({ handleSubmit, handleSubmitWithRedirect, ...props }) => {
  const form = useForm();
  const { values: v } = form.getState();
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  const [updateAnonymousReservationRequestStatus, updateAnonymousReservationRequestStatusData] = useCreate(`anonymous-reservation-requests/${v.anonymousRequest}/status`, { status: 'user-created' });


  const handleClick = useCallback(async () => {
    const { values } = form.getState();

    console.log('values', values);

    // if (values.anonymousRequest !== undefined) {
    //   dataProvider.create('users', { data: form.getState().values })
    //     .then(() => updateAnonymousReservationRequestStatus())
    //     .then(() => redirectTo(`/anonymous-reservation-requests/${v.anonymousRequest}/show`))
    //     .catch((err) => {
    //       Swal.fire('Errore', err.message, 'error');
    //     });
    // } else {
    //   handleSubmitWithRedirect('list');
    // }
  }, [form]);

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};


const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="Conferma" />
  </Toolbar>
));

export const ReservationRequestCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
        <ReservationRequestForm />
      </SimpleForm>
    </Create>
  );

  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput reference="suppliers" source="first_name"><SelectInput optionText="id" /></ReferenceInput>
        <ReferenceInput reference="users"><SelectInput optionText="id" /></ReferenceInput>
        <ReferenceInput reference="users"><SelectInput optionText="id" /></ReferenceInput>

        <TextInput label="Nome" allowEmpty={false} source="first_name" />


        <NumberInput source="done_by.id" />
        <NumberInput source="supplier.id" />
        <DateInput source="address.line1" />
        <NumberInput source="service.id" />
        <DateInput source="date" />
        <ArrayInput source="timeslots">
          <SimpleFormIterator>
            <TextInput source="start" />
            <TextInput source="end" />
          </SimpleFormIterator>

        </ArrayInput>
        <NumberInput source="price_at_request_time" />
        <TextInput source="notes" />
        <TextInput source="status.value" />
        <DateInput source="created_at" />
      </SimpleForm>
    </Create>
  );
};

export default ReservationRequestCreate;
