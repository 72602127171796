import React, { FunctionComponent } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

const StatusField = ({
  className, source, record = {}, emptyText, ...rest
}) => {
  const value = get(record, source);


  const val = (() => {
    const { value: v } = value;
    if (v === 'completed') return 'Completata';
    if (v === 'pending') return 'Da valutare';
    if (v === 'booked') return 'Accettata';
    if (v === 'accepted') return 'Accettata';
    if (v === 'refused') return 'Rifiutata';
    if (v === 'in-progress') return 'In corso';
  })();

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
    >
      {val != null && typeof val !== 'string'
        ? JSON.stringify(val)
        : val || emptyText}
    </Typography>
  );
};

StatusField.defaultProps = {
  addLabel: true,
};

export default StatusField;
