import React, { useEffect } from 'react';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import {
  TextInput,
  ImageInput,
  Toolbar,
  SaveButton,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import ImageField from '../components/image-field';

const ServiceCategoryForm = () => {
  const form = useForm();
  const { values } = form.getState();

  return (
    <Grid container>
      {values.id ? <TextInput source="id" disabled label="ID" /> : null}
      {values.slug ? <TextInput source="slug" disabled label="Slug" /> : null}
      <TextInput source="name" label="Nome categoria" />
      <ImageInput source="media.main" label="Immagine principale" accept="image/*">
        <ImageField title="main" />
      </ImageInput>
      <RichTextInput source="description" label="Descrizione" />
    </Grid>
  );
};


export default ServiceCategoryForm;
