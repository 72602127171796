import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Responsive,
  SimpleList,
  ReferenceField,
  EmailField,
} from 'react-admin';

const ReservationRequestList = (props) => (
  <List title="Gestione prenotazioni" {...props}>
    <Responsive
      small={(
        <SimpleList
          primaryText={(service) => (service ? service.name : '')}
          tertiaryText={(service) => (service ? service.id : '')}
        />
      )}
      medium={(
        <Datagrid rowClick="show">
          <TextField source="id" label="ID" />
          <TextField source="full_name" label="Nome Cognome" />
          <EmailField source="email" label="Email" />
          <TextField source="phone" label="Numero di telefono" />
          <ReferenceField source="supplier_id" label="Fornitore" reference="users">
            <TextField source="info.rag_soc" />
          </ReferenceField>
          <DateField showTime source="created_at" label="Data creazione" />
          <DateField source="updated_at" label="Data ultimo aggiornamento" showTime />
        </Datagrid>
      )}
    />
  </List>
);

export default ReservationRequestList;
