import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  BooleanField,
  Responsive,
  SimpleList,
  EditButton,
  FunctionField,
  ReferenceFieldController,
} from "react-admin";

// import UserFilters from './UserFilters';

const UserList = (props) => (
  <List title="Gestione utenti" {...props}>
    <Responsive
      small={
        <SimpleList
          primaryText={(user) => (user ? user.full_name : "")}
          secondaryText={(user) => (user ? user.email : "")}
          tertiaryText={(user) => (user ? user.id : "")}
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField label="Tipologia" source="type" />
          <FunctionField
            label="Nome"
            render={(user) => `${user.first_name} ${user.last_name}`}
          />
          <DateField label="Data di nascita" source="birthday" />
          <TextField label="Sesso" source="gender" />
          <EmailField source="email" />
          <TextField source="provider" />
          <BooleanField label="Email verificata" source="email_verified" />
          <TextField label="Numero di telefono" source="mobile_number" />
          <BooleanField
            label="Numero verificato"
            source="phone_number_verified"
          />
        </Datagrid>
      }
    />
  </List>
);

export default UserList;
