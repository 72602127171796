import React, { useEffect } from 'react';
import {
  Show,
  TextField,
  DateField,
  ReferenceField,
  useShowController,
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  TopToolbar,
  EditButton,
  DeleteButton,
  useCreate,
  useRefresh,
  EmailField,
} from 'react-admin';
import { CircularProgress, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

import StatusField from '../../components/status-field';
import AddressField from '../../components/address-field';
import PriceField from '../../components/price-field';

const Actions = ({
  basePath, data, resource, ...rest
}) => {
  const [createUser, createUserData] = useCreate(`reservation-requests/${data.id}/status`, {
    status: 'accepted',
    message: 'Richiesta di prestazione accettata da amministratore',
    reservationId: data.id,
  });

  const [createReservationRequest, createReservationRequestData] = useCreate(`reservation-requests/${data.id}/status`, {
    status: 'refused',
    message: 'Richiesta di prestazione rifiutata da amministratore',
    reservationId: data.id,
  });

  const refresh = useRefresh();

  useEffect(() => {
    if (createUserData.loaded) {
      refresh();
    }
  }, [refresh, createUserData]);

  useEffect(() => {
    if (createReservationRequestData.loaded) {
      refresh();
    }
  }, [refresh, createReservationRequestData]);

  return (
    <TopToolbar>
      {/* Add your custom actions */}
      {data.status.value === 'pending' ? (
        <>
          <Button
            component={Link}
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            to={{
              pathname: '/users/create',
              state: {
                record: {
                  anonymousRequest: data.id,
                  type: 'needy',
                  first_name: data.first_name,
                  last_name: data.last_name,
                  mobile_number: data.phone,
                  email: data.email,
                  addressString: data.address,

                  cod_fisc: '123123123123',
                  phone_number: data.phone,
                  password: '123123123',
                  birthday: '08/11/1995',
                },
              },
            }}
          >
            Crea utente
          </Button>
        </>
      ) : null}
      {data.status.value === 'user-created' ? (
        <Button
          component={Link}
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          to={{
            pathname: '/reservation-requests/create',
            state: {
              record: {
                needy_id: data.user_id,
              },
            },
          }}
        >
          Crea richiesta prenotazione
        </Button>
      ) : null}
      <DeleteButton basePath={basePath} record={data} label="Cancella" />
    </TopToolbar>
  );
};

const ReservationRequestShow = (props) => {
  const { record } = useShowController(props);

  if (record === undefined) {
    return <CircularProgress />;
  }

  return (
    <Show title={`Richiesta di prenotazione ID: ${record.id}`} {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <StatusField source="status" label="Stato" />
        {record.user_id !== undefined && record.user_id !== null ? (
          <ReferenceField source="user_id" reference="users" label="Utente registrato">
            <TextField source="full_name" />
          </ReferenceField>
        ) : null}
        <TextField source="full_name" label="Nome Cognome" />
        <EmailField source="email" label="Email" />
        <TextField source="phone" label="Numero di telefono" />
        <TextField source="address" label="Indirizzo" />
        <ReferenceField source="supplier_id" reference="users" label="Fornitore">
          <TextField source="info.rag_soc" />
        </ReferenceField>
        <ReferenceField source="supplier_service_id" reference="supplier-services" label="Servizio">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="notes" label="Note" />
        <DateField source="date" label="Data" />
        <ArrayField source="timeslots" label="Slot">
          <Datagrid>
            <TextField source="start" label="Inizio" />
            <TextField source="end" label="Fine" />
          </Datagrid>
        </ArrayField>
        <PriceField label="Costo totale" />
        <DateField source="created_at" label="Data creazione" showTime />
      </SimpleShowLayout>
    </Show>
  );
};

export default ReservationRequestShow;
