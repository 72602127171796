import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ImageField,
  useShowController,
  RichTextField,
} from 'react-admin';
import { CircularProgress } from '@material-ui/core';

const UserShow = (props) => {
  const { record } = useShowController(props);

  if (record === undefined) {
    return <CircularProgress />;
  }

  return (
    <Show title={`Categoria ${record.name} (ID: ${record.id})`} {...props}>
      <TabbedShowLayout>
        <Tab label="Generale">
          <TextField source="id" label="ID" />
          <TextField source="slug" label="Slug" />
          <TextField source="name" label="Nome" />
          <RichTextField source="description" label="Descrizione" />
        </Tab>
        <Tab label="media" path="media">
          {record.media.main !== undefined || record.media.main !== null ? <ImageField source="media.main" title="main" label="Immagine" /> : null}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
