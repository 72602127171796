import React, { useEffect, useState } from 'react';
import {
  SelectInput,
  useDataProvider,
} from 'react-admin';

import UserPaymentQuickCreateButton from './UserPaymentQuickCreateButton';

import './styles.css';

const UserAddressInput = ({
  className, source, record = {}, emptyText, userId, ...rest
}) => {
  const dataProvider = useDataProvider();
  const [paymentMethods, setPaymentMethods] = useState();

  useEffect(() => {
    if (userId !== undefined) {
      setPaymentMethods(undefined);
      getPaymentMethods();
    }
  }, [userId]);

  const getPaymentMethods = () => {
    dataProvider.getOne(`users/${userId}/payment-methods`)
      .then(({ data }) => {
        setPaymentMethods(data);
      });
  };


  return (
    <div className="user-address-input">
      <SelectInput
        label="Metodo di pagamento"
        source="paymentMethod"
        disabled={paymentMethods === undefined}
        choices={paymentMethods !== undefined ? paymentMethods.map((paymentMethod) => ({ id: paymentMethod.id, name: `**** **** **** ${paymentMethod.card.last4}` })) : []}
      />
      <UserPaymentQuickCreateButton
        onAddComplete={() => {
          getPaymentMethods();
        }}
        userId={userId}
      />
    </div>
  );
};

UserAddressInput.defaultProps = {
  addLabel: true,
};


export default UserAddressInput;
