import React, { useEffect, useCallback } from 'react';
import {
  Edit,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextInput,
  DateInput,
  SelectInput,
  Toolbar,
  SaveButton,
  useEditController,
  useCreate,
  useRedirect,
  useNotify,
} from 'react-admin';
import { useForm, useField } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core';

import { getMediaUrl } from '../../utils';

const PostTitle = ({ record }) => (
  <span>
    Fornitore
    {' '}
    {record ? `"${record.info.rag_soc}"` : ''}
    {' '}
  </span>
);

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const SupplierEdit = (props) => {
  const {
    record,
  } = useEditController(props);

  const [value, setValue] = React.useState('asd');

  useEffect(() => {
    if (record) {
      setValue(record.info.status);
      console.log(record.info);
    }
  }, [record]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const CustomSave = ({ handleSubmit, handleSubmitWithRedirect, ...props }) => {
    const form = useForm();
    const handleClick = useCallback(() => {
      form.change('status', value);

      handleSubmit();
      // handleSubmitWithRedirect('list');
    }, [form]);

    console.log('save props', props);

    // override handleSubmitWithRedirect with custom logic
    return <SaveButton handleSubmitWithRedirect={handleClick} />;
  };

  const CustomToolbar = withStyles(toolbarStyles)((props) => (
    <Toolbar {...props}>
      <CustomSave />
    </Toolbar>
  ));

  const styleDocument = {
    width:"200px",
    height: "200px",
    objectFit: 'cover'
  };

  const getFileExtension = (filename) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
  }

  const returnAImageOPdfDownload = (namefile, altImg) => {

    if (!namefile) {
      return false;
    }

    let nameFileExt = getFileExtension(namefile);

    if (nameFileExt.includes("png") || nameFileExt.includes("jpg") || nameFileExt.includes("jpeg")) {
      return (
       <img style={styleDocument} src={getMediaUrl(namefile)} alt={altImg} />
      )
    }

    if (nameFileExt.includes("pdf")) {
      return (
        <a href={getMediaUrl(namefile)} alt={altImg} download>Scarica il pdf</a>
      )
    }

  }

  return (
    <Edit title={<PostTitle />} {...props}>
      {record === undefined ? <CircularProgress /> : (
        <TabbedForm toolbar={<CustomToolbar />}>
          <FormTab label="Fornitore">
            <Typography variant="h5" component="h2" style={{ width: '100%', marginBottom: 10 }}>
              {record.info.rag_soc}
            </Typography>
            <Typography component="p" style={{ width: '100%' }}>
              {record.info.description}
            </Typography>
            <FormControl component="fieldset" style={{ marginTop: 30 }}>
              <FormLabel component="legend">Stato</FormLabel>
              <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                <FormControlLabel value="approved" control={<Radio />} label="Approvato" />
                <FormControlLabel value="not-approved" control={<Radio />} label="Non approvato" />
              </RadioGroup>
            </FormControl>
          </FormTab>
          <FormTab label="Documenti">
            {record.info.doc_front_path !== null ? (
              <>
                <Typography variant="h6" component="h2" style={{ width: '100%', marginBottom: 10 }}>
                  Fronte
                </Typography>
                {returnAImageOPdfDownload(getMediaUrl(record.info.doc_front_path), "Documento fronte")}
              </>
            ) : <p style={{ width: '100%' }}>L'utente non ha fornito il documento (fronte)</p>}

            {record.info.doc_back_path !== null ? (
              <>
                <Typography variant="h6" component="h2" style={{ width: '100%', marginBottom: 10 }}>
                  Retro
                </Typography>
                {returnAImageOPdfDownload(getMediaUrl(record.info.doc_back_path), "Documento retro")}
              </>
            ) : <p style={{ width: '100%' }}>L'utente non ha fornito il documento (retro)</p>}

          </FormTab>
        </TabbedForm>
      )}
    </Edit>
  );
};

export default SupplierEdit;
