import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Responsive,
  SimpleList,
  ReferenceField,
  ReferenceFieldController,
} from 'react-admin';

// import UserFilters from 'components/filters/Service';

const Panel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.description }} />
);

const ServiceList = (props) => (
  <List title="Gestione servizi" {...props}>
    <Responsive
      small={(
        <SimpleList
          primaryText={(service) => (service ? service.name : '')}
          tertiaryText={(service) => (service ? service.id : '')}
        />
      )}
      medium={(
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="name" label="Nome" />
          <ReferenceField source="category_id" label="Categoria" reference="service-categories">
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="needs_qualification" label="Serve qualifica" />
          <DateField source="created_at" label="Data creazione" showTime />
          <DateField source="updated_at" label="Data ultimo aggiornamento" showTime />
        </Datagrid>
              )}
    />
  </List>
);

/*

<ReferenceField source="super_service_id" label={'Servizio padre'} reference="services">
                        <TextField source="name" />
                    </ReferenceField>

                             <ReferenceFieldController label="Servizio padre" source="super_service_id" reference="services">
            {({ referenceRecord }) => ((referenceRecord) ? referenceRecord.name : 'N/A')}
          </ReferenceFieldController>

                    */

export default ServiceList;
