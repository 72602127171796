import React, { useEffect, useCallback } from 'react';
import {
  Edit,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextInput,
  DateInput,
  ImageField,
  ImageInput,
  SelectInput,
  Toolbar,
  SaveButton,
  useEditController,
  useCreate,
  useRedirect,
  useNotify,
} from 'react-admin';
import { useForm, useField } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core';

import moment from 'moment';
import { getMediaUrl } from '../../utils';
import UserForm from '../../forms/UserForm';


const PostTitle = ({ record }) => (
  <span>
    Utente
    {' '}
    {record ? `"${record.full_name}"` : ''}
    {' '}
  </span>
);


const CustomImageField = (props) => {
  const { record } = props;

  return typeof record === 'string' ? (
    <img src={record} style={{ width: 180 }} />
  ) : (
    <ImageField {...props} />
  );
};

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};


const UserEdit = (props) => {
  const {
    record,
  } = useEditController(props);

  const CustomSave = ({ handleSubmit, handleSubmitWithRedirect, ...props }) => {
    const form = useForm();
    const handleClick = useCallback(() => {
      const { values } = form.getState();

      form.change('password_confirmation', values.password);
      form.change('email_confirmation', values.email);
      form.change('birthday', moment(values.birthday).format('DD-MM-YYYY'));

      handleSubmit();
      // handleSubmitWithRedirect('list');
    }, [form]);

    // override handleSubmitWithRedirect with custom logic
    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
  };

  const CustomToolbar = withStyles(toolbarStyles)((props) => (
    <Toolbar {...props}>
      <CustomSave />
    </Toolbar>
  ));

  return (
    <Edit title={<PostTitle />} {...props}>
      {record === undefined ? <CircularProgress /> : (
        <TabbedForm toolbar={<CustomToolbar />}>
          <FormTab label="Utente">
            <Typography variant="h5" component="h2" style={{ width: '100%', marginBottom: 10 }}>
              {record.full_name}
            </Typography>

            <UserForm />
          </FormTab>
          <FormTab label="Media">
            <Typography variant="h5" component="h2" style={{ width: '100%', marginBottom: 10 }}>
              {record.full_name}
            </Typography>


            <Grid container>
              <Grid item xs={12} md={6}>

                <ImageInput source="media.profile" label="Foto profilo" accept="image/*">
                  <CustomImageField title="profile" />
                </ImageInput>
              </Grid>

              <Grid item xs={12} md={6}>
                <ImageInput source="media.cover" label="Foto cover" accept="image/*">
                  <CustomImageField title="cover" />
                </ImageInput>
              </Grid>
            </Grid>


          </FormTab>
        </TabbedForm>
      )}
    </Edit>
  );
};

export default UserEdit;
