import React, { useState, useEffect, useCallback } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  fetchUtils,
  CardActions,
  ShowButton,
  DateInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useCreate,
  useRedirect,
  useNotify,
  useUpdate,
  useDataProvider,
} from 'react-admin';
import { useForm, useField } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Modal from '@material-ui/core/Modal';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import { getFullAddress } from '../../utils';

import UserFormContent from '../../forms/UserForm';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};


const UserCreate = (props) => {
  const CustomSave = ({ handleSubmit, handleSubmitWithRedirect, ...props }) => {
    const form = useForm();
    const { values: v } = form.getState();
    const dataProvider = useDataProvider();
    const redirectTo = useRedirect();
    const [updateAnonymousReservationRequestStatus, updateAnonymousReservationRequestStatusData] = useCreate(`anonymous-reservation-requests/${v.anonymousRequest}/status`, { status: 'user-created' });


    const handleClick = useCallback(async () => {
      const { values } = form.getState();
      form.change('password_confirmation', values.password);
      form.change('email_confirmation', values.email);
      form.change('birthday', moment(values.birthday).format('DD-MM-YYYY'));

      if (values.anonymousRequest !== undefined) {
        dataProvider.create('users', { data: form.getState().values })
          .then(() => updateAnonymousReservationRequestStatus())
          .then(() => redirectTo(`/anonymous-reservation-requests/${v.anonymousRequest}/show`))
          .catch((err) => {
            Swal.fire('Errore', err.message, 'error');
          });
      } else {
        handleSubmitWithRedirect('list');
      }
    }, [form]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
  };

  const CustomToolbar = withStyles(toolbarStyles)((props) => (
    <Toolbar {...props}>
      <CustomSave />
    </Toolbar>
  ));

  return (
    <Create {...props}>
      <SimpleForm
        asyncValidate={asyncValidation}
        validate={validateUserCreation}
        toolbar={<CustomToolbar />}
      >
        <UserFormContent {...props} />
      </SimpleForm>
    </Create>
  );
};

const asyncValidation = (values) => new Promise(async (resolve, reject) => {
  const { fetchJson } = fetchUtils;
  const { email } = values;
  const errors = {};

  if (validateEmail(email)) {
    const isEmailUsed = await fetchJson(`${process.env.REACT_APP_API_URL}/users?filter[email]=${email}&filter[trashed]=true`, {
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      }),
    })
      .then((response) => JSON.parse(response.body))
      .then((response) => response.data.length > 0);

    if (isEmailUsed) { errors.email = 'L\'email è già in uso'; }
  }

  if (errors.email !== undefined) {
    reject(errors);
  } else {
    resolve();
  }
});

const validateUserCreation = (values, x, y) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = ['Il campo è obbligatorio'];
  }

  if (!values.last_name) {
    errors.last_name = ['Il campo è obbligatorio'];
  }

  if (!values.email) {
    errors.email = ['Il campo è obbligatorio'];
  }

  if (!values.phone_number) {
    errors.phone_number = ['Il campo è obbligatorio'];
  }

  if (!values.mobile_number) {
    errors.phone_number = ['Il campo è obbligatorio'];
  }

  if (!values.birthday) {
    errors.birthday = ['Il campo è obbligatorio'];
  }

  if (!values.gender) {
    errors.gender = ['Il campo è obbligatorio'];
  }

  if (!values.type) {
    errors.type = ['Il campo è obbligatorio'];
  }

  if (values.type === 'supplier') {
    if (!values.rag_soc) {
      errors.rag_soc = ['Il campo è obbligatorio'];
    }

    if (!values.rag_soc) {
      errors.piva = ['Il campo è obbligatorio'];
    }

    if (!values.cod_fisc_supplier) {
      errors.cod_fisc_supplier = ['Il campo è obbligatorio'];
    }
  } else if (!values.cod_fisc) {
    errors.cod_fisc = ['Il campo è obbligatorio'];
  }


  return errors;
};

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default UserCreate;
