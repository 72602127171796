import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  DateField,
  NumberField,
  BooleanField,
  ReferenceManyField,
  Datagrid,
  EditButton,
  ReferenceField,
  EmailField,
  ArrayField,
  ImageField,
  useShowController,
  ReferenceArrayField,
  SingleFieldList,
} from "react-admin";
import { CircularProgress } from "@material-ui/core";

import StatusField from "../../components/status-field";
import TimeslotChip from "../../components/timeslots-chip";
import PriceField from "../../components/price-field";

const UserShow = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props);
  console.log("asd", record);

  if (record === undefined) {
    return <CircularProgress />;
  }

  return (
    <Show title={`Utente ${record.full_name} (ID: ${record.id})`} {...props}>
      <TabbedShowLayout>
        <Tab label="Generale">
          <TextField source="id" label="Id utente" />
          <TextField source="type" label="Tipologia" />
          <TextField source="first_name" label="Nome" />
          <TextField source="last_name" label="Cognome" />
          <TextField source="cod_fisc" label="Codice fiscale" />
          <EmailField source="email" label="Email" />
          <TextField source="provider" label="Provider" />
          <BooleanField source="email_verified" label="Email verificata" />
          <TextField source="phone_number" label="Numero di telefono fisso" />
          <BooleanField
            source="phone_number_verified"
            label="Numero di telefono fisso verificato"
          />
          <TextField source="mobile_number" label="Numero di telefono mobile" />
          <BooleanField
            source="mobile_number_verified"
            label="Numero di telefono mobile verificato"
          />
          <DateField source="birthday" label="Data di nascita" />
          <TextField source="gender" label="Sesso" />
        </Tab>

        <Tab label="media" path="media">
          {record.media.profile !== undefined ||
          record.media.profile !== null ? (
            <ImageField
              source="media.profile"
              title="profile"
              label="Immagine del profilo"
            />
          ) : null}
          {record.media.cover !== undefined || record.media.cover !== null ? (
            <ImageField
              source="media.cover"
              title="cover"
              label="Immagine di copertina"
            />
          ) : null}
          <RichTextField source="body" addLabel={false} />
        </Tab>

        {record.type === "carer" ? (
          <Tab label="needy" path="needy">
            <ArrayField source="needy">
              <Datagrid>
                <TextField source="id" />
                <TextField source="full_name" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <EmailField source="email" />
                <TextField source="phone_number" />
                <TextField source="mobile_number" />
                <DateField source="birthday" />
                <BooleanField source="email_verified" />
                <BooleanField source="phone_number_verified" />
                <TextField source="gender" />
                <TextField source="type" />
                <TextField source="media.profile" />
              </Datagrid>
            </ArrayField>
          </Tab>
        ) : null}

        <Tab label="Richieste di prenotazione" path="reservation-requests">
          <ReferenceArrayField
            addLabel={false}
            reference="reservation-requests"
            source="reservation_requests_ids"
          >
            <Datagrid rowClick="show">
              <TextField source="id" label="ID" />
              <StatusField source="status" label="Status" />
              <ReferenceField
                source="done_by.id"
                label="Eseguita da"
                reference="users"
              >
                <TextField source="full_name" />
              </ReferenceField>
              <ReferenceField source="needy.id" label="Needy" reference="users">
                <TextField source="full_name" />
              </ReferenceField>
              <ReferenceField
                source="supplier.id"
                label="Fornitore"
                reference="users"
              >
                <TextField source="info.rag_soc" />
              </ReferenceField>
              <PriceField
                source="price_at_request_time"
                label="Prezzo orario"
              />
              <DateField source="date" label="Data" />
              <ArrayField source="timeslots">
                <SingleFieldList>
                  <TimeslotChip />
                </SingleFieldList>
              </ArrayField>
              <DateField source="created_at" label="Data creazione" showTime />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>

        <Tab label="Prenotazioni" path="reservations">
          <ReferenceArrayField
            addLabel={false}
            reference="reservations"
            source="reservations_ids"
          >
            <Datagrid rowClick="show">
              <TextField source="id" label="ID" />
              <StatusField source="status" label="Status" />
              <DateField source="reservation_request.date" label="Data" />
              <ReferenceField
                source="reservation_request.id"
                label="Richiesta"
                reference="reservation-requests"
              >
                <TextField source="id" />
              </ReferenceField>
              <BooleanField source="is_payed" label="Pagamento effettuato" />
              <DateField source="created_at" label="Data creazione" showTime />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>

        <Tab label="Recensioni" path="reviews">
          <ReferenceArrayField
            addLabel={false}
            reference="reservation-reviews"
            source="reviews_ids"
          >
            <Datagrid rowClick="show">
              <TextField source="id" label="ID" />
              {record.type === "supplier" ? (
                <ReferenceField
                  source="user_id"
                  label="Fatta da"
                  reference="users"
                >
                  <TextField source="full_name" />
                </ReferenceField>
              ) : null}
              <ReferenceField
                source="reservation_id"
                label="Prestazione"
                reference="reservations"
              >
                <TextField source="id" />
              </ReferenceField>
              <TextField source="score" label="Valutazione" />
              <DateField source="created_at" label="Data creazione" showTime />
              <TextField source="notes" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField label="Id" source="id" />
          <TextField source="title" />
          <TextField source="teaser" />
        </Tab>
        <Tab label="body" path="body">
          <RichTextField source="body" addLabel={false} />
        </Tab>
        <Tab label="Miscellaneous" path="miscellaneous">
          <TextField
            label="Password (if protected post)"
            source="password"
            type="password"
          />
          <DateField label="Publication date" source="published_at" />
          <NumberField source="average_note" />
          <BooleanField
            label="Allow comments?"
            source="commentable"
            defaultValue
          />
          <TextField label="Nb views" source="views" />
        </Tab>
        <Tab label="comments" path="comments">
          <ReferenceManyField
            reference="comments"
            target="post_id"
            addLabel={false}
          >
            <Datagrid>
              <TextField source="body" />
              <DateField source="created_at" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
