import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Responsive,
  SimpleList,
  ReferenceField,
  ArrayField,
  SingleFieldList,
} from 'react-admin';

import PriceField from '../../components/price-field';
import StatusField from '../../components/status-field';
import TimeslotChip from '../../components/timeslots-chip';

const ReservationRequestList = (props) => (
  <List title="Gestione prenotazioni" {...props}>
    <Responsive
      small={(
        <SimpleList
          primaryText={(service) => (service ? service.name : '')}
          tertiaryText={(service) => (service ? service.id : '')}
        />
      )}
      medium={(
        <Datagrid rowClick="show">
          <TextField source="id" label="ID" />
          <StatusField source="status" label="Status" />
          <ReferenceField source="done_by.id" label="Eseguita da" reference="users">
            <TextField source="full_name" />
          </ReferenceField>
          <ReferenceField source="needy.id" label="Needy" reference="users">
            <TextField source="full_name" />
          </ReferenceField>
          <ReferenceField source="supplier.id" label="Fornitore" reference="users">
            <TextField source="info.rag_soc" />
          </ReferenceField>
          <PriceField source="price_at_request_time" label="Prezzo orario" />
          <DateField source="date" label="Data" />
          <ArrayField source="timeslots">
            <SingleFieldList>
              <TimeslotChip />
            </SingleFieldList>
          </ArrayField>
          <DateField showTime source="created_at" label="Data creazione" />
          <DateField source="updated_at" label="Data ultimo aggiornamento" showTime />
        </Datagrid>
      )}
    />
  </List>
);

export default ReservationRequestList;
