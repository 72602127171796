import React, { useEffect, useState } from 'react';
import {
  SelectArrayInput,
  useDataProvider,
} from 'react-admin';

import './styles.css';

const TimeslotInput = ({
  className, source, record = {}, emptyText, supplierId, serviceId, date = '2020-05-27', ...rest
}) => {
  const dataProvider = useDataProvider();
  const [timeslots, setTimeslots] = useState();

  useEffect(() => {
    if (supplierId !== undefined && supplierId !== undefined && date !== undefined) {
      setTimeslots(undefined);
      getPaymentMethods();
    }
  }, [supplierId, supplierId, date]);

  const getPaymentMethods = () => {
    dataProvider.getOne(`suppliers/${supplierId}/timeslots?serviceId=${serviceId}&date=${date}`)
      .then(({ data }) => {
        setTimeslots(data);
      });
  };

  return (
    <div className="timeslots-input">
      <SelectArrayInput
        disabled={!(supplierId !== undefined && serviceId !== undefined && date !== undefined)}
        label="Timeslots"
        source="timeslots"
        choices={timeslots !== undefined ? timeslots.map((timeslot) => ({ id: timeslot, name: `${timeslot.start} - ${timeslot.end}` })) : []}
      />
    </div>
  );
};

TimeslotInput.defaultProps = {
  addLabel: true,
};


export default TimeslotInput;
