import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
  ReferenceField,
  DateField,
  NumberField,
} from 'react-admin';
import { CircularProgress } from '@material-ui/core';

import PriceField from '../../components/price-field';

const SupplierServiceShow = (props) => {
  const { record } = useShowController(props);

  if (record === undefined) {
    return <CircularProgress />;
  }

  return (
    <Show title={`Utente ${record.full_name} (ID: ${record.id})`} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <ReferenceField source="supplier_id" reference="suppliers" label="Ragione Sociale"><TextField source="info.rag_soc" /></ReferenceField>
        <ReferenceField source="service_id" reference="services"><TextField source="name" /></ReferenceField>
        <TextField source="description" label="Descrizione" />
        <PriceField source="price" label="Prezzo" />
        <NumberField source="duration" label="Durata" />
        <DateField showTime source="created_at" label="Data creazione" showTime />
        <DateField showTime source="updated_at" label="Data ultimo aggiornamento" />
      </SimpleShowLayout>
    </Show>
  );
};

export default SupplierServiceShow;
