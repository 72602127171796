import React from 'react';
import { ImageField } from 'react-admin';

const CustomImageField = (props) => {
  const { record } = props;

  return typeof record === 'string' ? (
    <img src={record} style={{ width: 180 }} />
  ) : (
    <ImageField {...props} />
  );
};

export default CustomImageField;
