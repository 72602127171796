import React, { useState, useEffect } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  fetchUtils,
  CardActions,
  ShowButton,
  DateInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useCreate,
  useRedirect,
  useNotify,
  useEditController,
} from 'react-admin';
import { useForm, useField } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Modal from '@material-ui/core/Modal';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import { getFullAddress } from '../utils';

import AddressInput from '../components/address-input';

const UserForm = () => {
  const [selectedType, setSelectedType] = useState('');
  const form = useForm();

  console.log('UserForm', form.getState().values);

  return (
    <>
      <Grid container style={{ width: '100%', paddingRight: 15 }}>
        <SelectInput
          style={{ width: '100%' }}
          allowEmpty={false}
          label="Tipologia"
          source="type"
          onChange={(event) => setSelectedType(event.target.value)}
          choices={[
            { id: 'carer', name: 'Carer' },
            { id: 'needy', name: 'Needy' },
            { id: 'supplier', name: 'Fornitore' },
            { id: 'staff', name: 'Staff' },
          ]}
        />
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6}>
          <TextInput label="Nome" allowEmpty={false} source="first_name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Cognome" source="last_name" allowEmpty={false} />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectInput
            label="Sesso"
            source="gender"
            choices={[
              { id: 'Uomo', name: 'Uomo' },
              { id: 'Donna', name: 'Donna' },
              { id: 'Preferisco non specificare', name: 'Preferisco non specificare' },
            ]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DateInput label="Data di nascita" source="birthday" />
        </Grid>

        <Grid item xs={12} md={6}>
          <AddressInput label="Indirizzo di residenza" onChange={(a) => form.change('address', a)} />
        </Grid>

        {selectedType !== 'supplier' && (
        <Grid item xs={12} md={6}>
          <TextInput label="Codice fiscale" source="cod_fisc" allowEmpty={false} />
        </Grid>
        )}


        <Grid item xs={12} md={6}>
          <TextInput label="Telefono fisso" source="phone_number" />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextInput label="Telefono cellulare" source="mobile_number" allowEmpty={false} />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextInput label="Email" source="email" allowEmpty={false} />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextInput label="Password" source="password" allowEmpty={false} />
        </Grid>

        {selectedType === 'supplier' && (
        <>
          <Grid item xs={12} md={6}>
            <TextInput label="Ragione sociale" source="rag_soc" allowEmpty={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput label="Codice fiscale" source="cod_fisc_supplier" allowEmpty={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput label="Partiva IVA" source="piva" allowEmpty={false} />
          </Grid>
        </>
        )}

      </Grid>
    </>
  );
};

export default UserForm;
