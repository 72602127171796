import React, { FunctionComponent } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { getFullAddress } from '../../utils';

const AddressField = ({
  className, source, record = {}, emptyText, ...rest
}) => {
  const value = get(record, source);
  const address = getFullAddress(value);

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
    >
      {address != null && typeof address !== 'string'
        ? JSON.stringify(address)
        : address || emptyText}
    </Typography>
  );
};

AddressField.defaultProps = {
  addLabel: true,
};


export default AddressField;
