import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';


import UserIcon from '@material-ui/icons/Group';
import ServiceIcon from '@material-ui/icons/ViewAgenda';
import ServiceCategoryIcon from '@material-ui/icons/ViewWeek';
import ReservationIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReservationInProgressIcon from '@material-ui/icons/AssignmentLate';
import ReservationRequestIcon from '@material-ui/icons/Assignment';
import ReservationReviewIcon from '@material-ui/icons/RateReview';
import SupplierIcon from '@material-ui/icons/Work';
import TimeslotIcon from '@material-ui/icons/Timelapse';

import './styles.css';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Dashboard = () => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [data, setData] = useState();


  useEffect(() => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('access_token')}` };

    fetch(`${process.env.REACT_APP_API_URL}/stats`, { headers })
      .then((d) => d.json())
      .then((d) => {
        const { data: da } = d;
        setData(da);
      });
  }, []);


  return (
    <div className="dashboard">
      <Typography style={{ marginTop: 10, marginBottom: 20 }} variant="h4" component="h2">
        Dashboard
      </Typography>


      {data === undefined ? <CircularProgress /> : (
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Totale utenti
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.users}
                  <UserIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  color="primary"
                  to={{ pathname: '/users' }}
                  size="small"
                >
                  Dettagli
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Carer
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.carers}
                  <UserIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  color="primary"
                  to={{ pathname: '/cares' }}
                  size="small"
                >
                  Dettagli
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Needy
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.needy}
                  <UserIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  color="primary"
                  to={{ pathname: '/needy' }}
                  size="small"
                >
                  Dettagli
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Fornitori
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.suppliers}
                  <SupplierIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  color="primary"
                  to={{ pathname: '/suppliers' }}
                  size="small"
                >
                  Dettagli
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Richieste di prenotazione
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.reservation_requests}
                  <ReservationRequestIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  color="primary"
                  to={{ pathname: '/reservation-requests' }}
                  size="small"
                >
                  Dettagli
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Prestazioni
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.reservations}
                  <ReservationIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  color="primary"
                  to={{ pathname: '/reservations' }}
                  size="small"
                >
                  Dettagli
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Prestazioni in corso
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.reservations_in_progress}
                  <ReservationInProgressIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{ height: 30 }} />
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Timeslot prenotati
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.timeslots}
                  <TimeslotIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <div style={{ height: 30 }} />
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Servizi
                </Typography>
                <Typography variant="h5" component="h2">
                  {data.totals.services}
                  <ServiceIcon />
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  color="primary"
                  to={{ pathname: '/services' }}
                  size="small"
                >
                  Dettagli
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      ) }


    </div>
  );
};

export default Dashboard;
