import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ArrayField,
  SingleFieldList,
  ChipField,
  DateField,
  BooleanField,
  Responsive,
  SimpleList,
  EditButton,
  FunctionField,
  ReferenceFieldController,
  ReferenceField,
} from 'react-admin';

// import UserFilters from './UserFilters';

const StatusField = ({ record = {}, source }) => {
  const map = {
    approved: 'Approvato',
    'not-approved': 'Non approvato',
  };

  return map[record.info.status];
};


export const SupplierList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <StatusField source="info.status" label="Stato" />
      <TextField source="info.rag_soc" label="Ragione sociale" />
      <ReferenceField source="id" label="Utente" reference="users">
        <TextField source="full_name" />
      </ReferenceField>
      <TextField source="info.piva" label="Partita iva" />
      <DateField source="created_at" label="Data creazione" showTime />
      <DateField source="updated_at" label="Data ultimo aggiornamento" showTime />
    </Datagrid>
  </List>
);
export default SupplierList;
