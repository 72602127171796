import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import {
  SelectInput,
  useDataProvider,
} from 'react-admin';
import { getFullAddress } from '../../utils';

import UserAddressQuickCreateButton from './UserAddressQuickCreateButton';

import './styles.css';

const UserAddressInput = ({
  className, source, record = {}, emptyText, userId, ...rest
}) => {
  const dataProvider = useDataProvider();
  const [addresses, setAddresses] = useState();

  useEffect(() => {
    if (userId !== undefined) {
      setAddresses(undefined);
      getAddresses();
    }
  }, [userId]);

  const getAddresses = () => {
    dataProvider.getOne(`users/${userId}/addresses`)
      .then(({ data }) => {
        setAddresses(data);
      });
  };


  return (
    <div className="user-address-input">
      <SelectInput
        label="Indirizzo"
        source="address.id"
        disabled={addresses === undefined}
        choices={addresses !== undefined ? addresses.map((address, index) => ({
          id: address.id,
          name: getFullAddress(address),
        })) : []}
      />
      <UserAddressQuickCreateButton
        onAddComplete={() => {
          getAddresses();
        }}
        userId={userId}
      />
    </div>
  );
};

UserAddressInput.defaultProps = {
  addLabel: true,
};


export default UserAddressInput;
