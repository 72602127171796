import React, { FunctionComponent } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

const SignatureField = ({
  className, source, record = {}, emptyText, ...rest
}) => {
  const value = get(record, source);

  console.log('SignatureField', value);


  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
    >
      {value != null && typeof value !== 'string'
        ? JSON.stringify(value)
        : value || emptyText}
    </Typography>
  );
};

SignatureField.defaultProps = {
  addLabel: true,
};

export default SignatureField;
