export const getFullAddress = (obj) => {
  if (obj === undefined) return '';

  if ('address' in obj) {
    return `${obj.address}, ${obj.postalCode} ${obj.city}`;
  }

  return `${obj.line1 ? obj.line1 : ''}${obj.line2 ? ` ${obj.line2}` : ''}${obj.line3 ? ` ${obj.line3}` : ''}, ${obj.postal_code} ${obj.city.name}`;
};

export const parseAddress = (data) => ({
  address: `${data[0].address_components.find((item) => item.types.includes('route')).long_name}, ${data[0].address_components.find((item) => item.types.includes('street_number')).long_name}`,
  city: data[0].address_components.find((item) => item.types.includes('locality')).long_name,
  postalCode: data[0].address_components.find((item) => item.types.includes('postal_code')).long_name,
  coordinates: {
    lat: data[0].geometry.location.lat(),
    lng: data[0].geometry.location.lng(),
  },
});

export const isValidEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export const getMediaUrl = (url) => {
  if (url === undefined || url === null) return null;

  if (url.includes('http')) {
    return url;
  }

  if (url.includes('my-seren-care.s3')) {
    return `https://${url}`;
  }

  return `${process.env.REACT_APP_BASE_URL}${url}`;
};

export const parseTotalPrice = (price, duration, timeslotsCount) => `${parseFloat(
  ((price * duration) / 6000) * timeslotsCount,
).toFixed(2)}€`;
