import React, { useEffect } from 'react';
import {
  Show,
  TextField,
  DateField,
  ReferenceField,
  useShowController,
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  TopToolbar,
  EditButton,
  DeleteButton,
  useCreate,
  useRefresh,
} from 'react-admin';
import { CircularProgress, Button } from '@material-ui/core';
import AcceptIcon from '@material-ui/icons/Check';
import RefuseIcon from '@material-ui/icons/Clear';

import StatusField from '../../components/status-field';
import AddressField from '../../components/address-field';
import PriceField from '../../components/price-field';

const Actions = ({
  basePath, data, resource, ...rest
}) => {
  const [acceptReservationRequest, acceptReservationRequestData] = useCreate(`reservation-requests/${data.id}/status`, {
    status: 'accepted',
    message: 'Richiesta di prestazione accettata da amministratore',
    reservationId: data.id,
  });

  const [refuseReservationRequest, stopReservationRequestData] = useCreate(`reservation-requests/${data.id}/status`, {
    status: 'refused',
    message: 'Richiesta di prestazione rifiutata da amministratore',
    reservationId: data.id,
  });

  const refresh = useRefresh();

  useEffect(() => {
    console.log('acceptReservationRequestData', acceptReservationRequestData);

    if (acceptReservationRequestData.loaded) {
      refresh();
    }
  }, [acceptReservationRequestData]);

  useEffect(() => {
    console.log('stopReservationRequestData', stopReservationRequestData);
    if (stopReservationRequestData.loaded) {
      refresh();
    }
  }, [stopReservationRequestData]);

  return (
    <TopToolbar>
      {/* Add your custom actions */}
      {data.status.value === 'pending' ? (
        <>

          <Button
            color="primary"
            size="small"
            startIcon={<AcceptIcon />}
            onClick={() => {
              acceptReservationRequest();
            }}
          >
            Accetta richiesta prestazione
          </Button>

          <Button
            color="primary"
            size="small"
            startIcon={<RefuseIcon />}
            onClick={() => {
              refuseReservationRequest();
            }}
          >
            Rifiuta richiesta prestazione
          </Button>

        </>
      ) : null}
      <EditButton basePath={basePath} record={data} label="Modifica" />
      <DeleteButton basePath={basePath} record={data} label="Cancella" />
    </TopToolbar>
  );
};

const ReservationRequestShow = (props) => {
  const { record } = useShowController(props);

  if (record === undefined) {
    return <CircularProgress />;
  }

  return (
    <Show title={`Richiesta di prenotazione ID: ${record.id}`} {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />

        <StatusField source="status" label="Stato" />

        <ReferenceField source="done_by.id" reference="users" label="Fatta da">
          <TextField source="full_name" />
        </ReferenceField>

        <ReferenceField source="needy.id" reference="users" label="Needy">
          <TextField source="full_name" />
        </ReferenceField>

        <ReferenceField source="supplier.id" reference="users" label="Fornitore">
          <TextField source="info.rag_soc" />
        </ReferenceField>

        <AddressField source="address" label="Indirizzo" />

        <ReferenceField source="service.service_id" reference="services" label="Servizio">
          <TextField source="name" />
        </ReferenceField>

        <TextField source="notes" label="Note" />

        <DateField source="date" label="Data" />

        <ArrayField source="timeslots" label="Slot">
          <Datagrid>
            <TextField source="start" label="Inizio" />
            <TextField source="end" label="Fine" />
          </Datagrid>
        </ArrayField>

        <PriceField label="Costo totale" />

        <DateField source="created_at" label="Data creazione" showTime />
      </SimpleShowLayout>
    </Show>
  );
};

export default ReservationRequestShow;
