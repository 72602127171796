import React from 'react';
import get from 'lodash/get';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';


const useStyles = makeStyles(
  {
    chip: { margin: 4 },
  },
  { name: 'RaChipField' },
);

export const TimeslotChip = (props) => {
  const {
    className,
    classes: classesOverride,
    source,
    record = {},
    emptyText,
    ...rest
  } = props;
  const classes = useStyles(props);
  const value = `${record.start} - ${record.end}`;

  console.log(value, record);


  if (value == null && emptyText) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
      >
        {emptyText}
      </Typography>
    );
  }

  return (
    <Chip
      className={classnames(classes.chip, className)}
      label={value}
    />
  );
};


export default TimeslotChip;
