import React, { useEffect } from 'react';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import {
  TextInput,
  ImageInput,
  Toolbar,
  SaveButton,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  BooleanField,
  AutocompleteInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import ImageField from '../components/image-field';

const ServiceForm = () => {
  const form = useForm();
  const { values } = form.getState();

  return (
    <Grid container>
      {values.id ? <TextInput source="id" disabled label="ID" /> : null}
      {values.slug ? <TextInput source="slug" disabled label="Slug" /> : null}
      <TextInput source="name" label="Nome servizio" />
      <ReferenceInput source="category_id" reference="service-categories">
        <AutocompleteInput optionText={(choice) => (choice ? `${choice.id} - ${choice.name}` : '')} />
      </ReferenceInput>
      <RichTextInput source="description" label="Descrizione" />
    </Grid>
  );
};


export default ServiceForm;
