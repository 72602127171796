import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Responsive,
  SimpleList,
  ReferenceField,
  ReferenceFieldController,
  NumberField,
} from 'react-admin';

import PriceField from '../../components/price-field';

const SupplierServiceList = (props) => (
  <List title="Gestione servizi" {...props}>
    <Responsive
      small={(
        <SimpleList
          primaryText={(service) => (service ? service.name : '')}
          tertiaryText={(service) => (service ? service.id : '')}
        />
      )}
      medium={(
        <Datagrid rowClick="show">
          <TextField source="id" label="ID" />
          <ReferenceField source="supplier_id" reference="suppliers" label="Ragione Sociale"><TextField source="info.rag_soc" /></ReferenceField>
          <ReferenceField source="service_id" reference="services"><TextField source="name" /></ReferenceField>
          <TextField source="description" label="Descrizione" />
          <PriceField source="price" label="Prezzo" />
          <NumberField source="duration" label="Durata" />
          <DateField source="created_at" label="Data creazione" showTime />
          <DateField source="updated_at" label="Data ultimo aggiornamento" showTime />
        </Datagrid>
              )}
    />
  </List>
);


export default SupplierServiceList;
